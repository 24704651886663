<bit-section>
  <bit-section-header>
    <h2 bitTypography="h6">{{ "itemHistory" | i18n }}</h2>
  </bit-section-header>
  <bit-card>
    <p class="tw-mb-1 tw-text-xs tw-text-muted tw-select-none">
      <span class="tw-font-bold">{{ "lastEdited" | i18n }}:</span>
      {{ cipher.revisionDate | date: "medium" }}
    </p>
    <p class="tw-mb-1 tw-text-xs tw-text-muted tw-select-none">
      <span class="tw-font-bold">{{ "dateCreated" | i18n }}:</span>
      {{ cipher.creationDate | date: "medium" }}
    </p>
    <p
      *ngIf="cipher.hasPasswordHistory && isLogin"
      class="tw-text-xs tw-text-muted tw-select-none"
      [ngClass]="{ 'tw-mb-3': cipher.hasPasswordHistory }"
    >
      <span class="tw-font-bold">{{ "datePasswordUpdated" | i18n }}:</span>
      {{ cipher.passwordRevisionDisplayDate | date: "medium" }}
    </p>
    <a
      *ngIf="cipher.hasPasswordHistory"
      class="tw-font-bold tw-no-underline"
      routerLink="/cipher-password-history"
      [queryParams]="{ cipherId: cipher.id }"
      bitTypography="body2"
    >
      {{ "passwordHistory" | i18n }}
    </a>
  </bit-card>
</bit-section>
