<ng-container *ngIf="!!cipher">
  <!-- ITEM DETAILS -->
  <app-item-details-v2
    [cipher]="cipher"
    [organization]="organization$ | async"
    [collections]="collections$ | async"
    [folder]="folder$ | async"
  >
  </app-item-details-v2>

  <!-- ADDITIONAL OPTIONS -->
  <ng-container *ngIf="cipher.notes">
    <app-additional-options [notes]="cipher.notes"> </app-additional-options>
  </ng-container>

  <!-- CUSTOM FIELDS -->
  <ng-container *ngIf="cipher.fields">
    <app-custom-fields-v2 [fields]="cipher.fields" [cipherType]="cipher.type">
    </app-custom-fields-v2>
  </ng-container>

  <!-- ATTACHMENTS SECTION -->
  <ng-container *ngIf="cipher.attachments">
    <app-attachments-v2-view [cipher]="cipher"> </app-attachments-v2-view>
  </ng-container>

  <!-- ITEM HISTORY SECTION -->
  <app-item-history-v2 [cipher]="cipher"> </app-item-history-v2>
</ng-container>
