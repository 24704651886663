<ng-container
  *ngIf="{
    open: sideNavService.open$ | async,
  } as data"
>
  <div
    *ngIf="data.open || icon"
    class="tw-relative"
    [ngClass]="[
      showActiveStyles
        ? 'tw-bg-background-alt4'
        : 'tw-bg-background-alt3 hover:tw-bg-primary-300/60',
      fvwStyles$ | async,
    ]"
  >
    <div
      [ngStyle]="{
        'padding-left': data.open ? (variant === 'tree' ? 2.5 : 1) + treeDepth * 1.5 + 'rem' : '0',
      }"
      class="tw-relative tw-flex"
    >
      <div [ngClass]="[variant === 'tree' ? 'tw-py-1' : 'tw-py-2']">
        <div
          #slotStart
          class="[&>*:focus-visible::before]:!tw-ring-text-alt2 [&>*:hover]:!tw-border-text-alt2 [&>*]:!tw-text-alt2"
        >
          <ng-content select="[slot=start]"></ng-content>
        </div>
        <!-- Default content for #slotStart (for consistent sizing) -->
        <div
          *ngIf="slotStart.childElementCount === 0"
          [ngClass]="{
            'tw-w-0': variant !== 'tree',
          }"
        >
          <button
            type="button"
            class="tw-invisible"
            [bitIconButton]="'bwi-angle-down'"
            size="small"
            aria-hidden="true"
          ></button>
        </div>
      </div>

      <ng-container *ngIf="route; then isAnchor; else isButton"></ng-container>

      <!-- Main content of `NavItem` -->
      <ng-template #anchorAndButtonContent>
        <div
          [title]="text"
          class="tw-truncate"
          [ngClass]="[
            variant === 'tree' ? 'tw-py-1' : 'tw-py-2',
            data.open ? 'tw-pr-4' : 'tw-text-center',
          ]"
        >
          <i
            class="bwi bwi-fw tw-text-alt2 tw-mx-1 {{ icon }}"
            [attr.aria-hidden]="data.open"
            [attr.aria-label]="text"
          ></i
          ><span
            *ngIf="data.open"
            [ngClass]="showActiveStyles ? 'tw-font-bold' : 'tw-font-semibold'"
            >{{ text }}</span
          >
        </div>
      </ng-template>

      <!-- Show if a value was passed to `this.to` -->
      <ng-template #isAnchor>
        <!-- The `fvw` class passes focus to `this.focusVisibleWithin$` -->
        <!-- The following `class` field should match the `#isButton` class field below -->
        <a
          class="fvw tw-w-full tw-truncate tw-border-none tw-bg-transparent tw-p-0 tw-text-start !tw-text-alt2 hover:tw-text-alt2 hover:tw-no-underline focus:tw-outline-none [&>:not(.bwi)]:hover:tw-underline"
          [routerLink]="route"
          [relativeTo]="relativeTo"
          [attr.aria-label]="ariaLabel || text"
          routerLinkActive
          [routerLinkActiveOptions]="routerLinkActiveOptions"
          [ariaCurrentWhenActive]="'page'"
          (isActiveChange)="setIsActive($event)"
          (click)="mainContentClicked.emit()"
        >
          <ng-container *ngTemplateOutlet="anchorAndButtonContent"></ng-container>
        </a>
      </ng-template>

      <!-- Show if `this.to` is falsy -->
      <ng-template #isButton>
        <!-- Class field should match `#isAnchor` class field above -->
        <button
          type="button"
          class="fvw tw-w-full tw-truncate tw-border-none tw-bg-transparent tw-p-0 tw-text-start !tw-text-alt2 hover:tw-text-alt2 hover:tw-no-underline focus:tw-outline-none [&>:not(.bwi)]:hover:tw-underline"
          (click)="mainContentClicked.emit()"
        >
          <ng-container *ngTemplateOutlet="anchorAndButtonContent"></ng-container>
        </button>
      </ng-template>

      <div
        #endSlot
        *ngIf="data.open"
        class="tw-flex -tw-ml-3 tw-pr-4 tw-gap-1 [&>*:focus-visible::before]:!tw-ring-text-alt2 [&>*:hover]:!tw-border-text-alt2 [&>*]:tw-text-alt2"
        [ngClass]="[
          variant === 'tree' ? 'tw-py-1' : 'tw-py-2',
          endSlot.childElementCount === 0 ? 'tw-hidden' : '',
        ]"
      >
        <ng-content select="[slot=end]"></ng-content>
      </div>
    </div>
  </div>
</ng-container>
