<ng-container *ngIf="loading">
  <i
    class="bwi bwi-spinner bwi-spin tw-text-muted"
    title="{{ 'loading' | i18n }}"
    aria-hidden="true"
  ></i>
  <span class="tw-sr-only">{{ "loading" | i18n }}</span>
</ng-container>
<form
  [formGroup]="formGroup"
  [bitSubmit]="submit"
  *ngIf="!loading && this.secretsManagerPlans"
  class="tw-pt-6"
>
  <bit-section>
    <app-org-info
      (changedBusinessOwned)="changedOwnedBusiness()"
      [formGroup]="formGroup"
      [createOrganization]="createOrganization"
      [isProvider]="!!providerId"
      [acceptingSponsorship]="acceptingSponsorship"
    >
    </app-org-info>
  </bit-section>
  <bit-section>
    <h2 bitTypography="h2">{{ "chooseYourPlan" | i18n }}</h2>
    <bit-radio-group formControlName="productTier" [block]="true">
      <div *ngFor="let selectableProduct of selectableProducts" class="tw-mb-3">
        <bit-radio-button [value]="selectableProduct.productTier" (change)="changedProduct()">
          <bit-label>{{ selectableProduct.nameLocalizationKey | i18n }}</bit-label>
          <bit-hint class="tw-text-sm"
            >{{ selectableProduct.descriptionLocalizationKey | i18n: "1" }}
            <ng-container
              *ngIf="
                selectableProduct.productTier === productTypes.Enterprise;
                else nonEnterprisePlans
              "
            >
              <ul class="tw-pl-0 tw-list-inside tw-mb-0">
                <li>{{ "includeAllTeamsFeatures" | i18n }}</li>
                <li *ngIf="selectableProduct.hasSelfHost">{{ "onPremHostingOptional" | i18n }}</li>
                <li *ngIf="selectableProduct.hasSso">{{ "includeSsoAuthentication" | i18n }}</li>
                <li *ngIf="selectableProduct.hasPolicies">
                  {{ "includeEnterprisePolicies" | i18n }}
                </li>
              </ul>
            </ng-container>
            <ng-template #nonEnterprisePlans>
              <ng-container
                *ngIf="
                  selectableProduct.productTier === productTypes.Teams &&
                    teamsStarterPlanIsAvailable;
                  else fullFeatureList
                "
              >
                <ul class="tw-pl-0 tw-list-inside tw-mb-0">
                  <li>{{ "includeAllTeamsStarterFeatures" | i18n }}</li>
                  <li>{{ "chooseMonthlyOrAnnualBilling" | i18n }}</li>
                  <li>{{ "abilityToAddMoreThanNMembers" | i18n: 10 }}</li>
                </ul>
              </ng-container>
              <ng-template #fullFeatureList>
                <ul class="tw-pl-0 tw-list-inside tw-mb-0">
                  <li *ngIf="selectableProduct.productTier == productTypes.Free">
                    {{ "limitedUsers" | i18n: selectableProduct.PasswordManager.maxSeats }}
                  </li>
                  <li
                    *ngIf="
                      selectableProduct.productTier != productTypes.Free &&
                      selectableProduct.productTier != productTypes.TeamsStarter &&
                      selectableProduct.PasswordManager.maxSeats
                    "
                  >
                    {{ "addShareLimitedUsers" | i18n: selectableProduct.PasswordManager.maxSeats }}
                  </li>
                  <li *ngIf="!selectableProduct.PasswordManager.maxSeats">
                    {{ "addShareUnlimitedUsers" | i18n }}
                  </li>
                  <li *ngIf="selectableProduct.PasswordManager.maxCollections">
                    {{
                      "limitedCollections" | i18n: selectableProduct.PasswordManager.maxCollections
                    }}
                  </li>
                  <li *ngIf="selectableProduct.PasswordManager.maxAdditionalSeats">
                    {{
                      "addShareLimitedUsers"
                        | i18n: selectableProduct.PasswordManager.maxAdditionalSeats
                    }}
                  </li>
                  <li *ngIf="!selectableProduct.PasswordManager.maxCollections">
                    {{ "createUnlimitedCollections" | i18n }}
                  </li>
                  <li *ngIf="selectableProduct.PasswordManager.baseStorageGb">
                    {{
                      "gbEncryptedFileStorage"
                        | i18n: selectableProduct.PasswordManager.baseStorageGb + "GB"
                    }}
                  </li>
                  <li *ngIf="selectableProduct.hasGroups">
                    {{ "controlAccessWithGroups" | i18n }}
                  </li>
                  <li *ngIf="selectableProduct.hasApi">{{ "trackAuditLogs" | i18n }}</li>
                  <li *ngIf="selectableProduct.hasDirectory">
                    {{ "syncUsersFromDirectory" | i18n }}
                  </li>
                  <li *ngIf="selectableProduct.hasSelfHost">
                    {{ "onPremHostingOptional" | i18n }}
                  </li>
                  <li *ngIf="selectableProduct.usersGetPremium">{{ "usersGetPremium" | i18n }}</li>
                  <li *ngIf="selectableProduct.productTier != productTypes.Free">
                    {{ "priorityCustomerSupport" | i18n }}
                  </li>
                </ul>
              </ng-template>
            </ng-template>
          </bit-hint>
        </bit-radio-button>
        <span *ngIf="selectableProduct.productTier == productTypes.Free" class="tw-pl-4">{{
          "freeForever" | i18n
        }}</span>
      </div>
    </bit-radio-group>
  </bit-section>

  <!-- Secrets Manager -->
  <bit-section>
    <sm-subscribe
      *ngIf="planOffersSecretsManager && !hasProvider"
      [formGroup]="formGroup.controls.secretsManager"
      [selectedPlan]="selectedSecretsManagerPlan"
      [upgradeOrganization]="!createOrganization"
    ></sm-subscribe>
  </bit-section>
  <bit-section>
    <button
      type="submit"
      buttonType="primary"
      bitButton
      bitFormButton
      [disabled]="!formGroup.valid"
    >
      {{ "submit" | i18n }}
    </button>
    <button
      type="button"
      buttonType="secondary"
      bitButton
      bitFormButton
      (click)="cancel()"
      *ngIf="showCancel"
    >
      {{ "cancel" | i18n }}
    </button>
  </bit-section>
</form>
